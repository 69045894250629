/* First, import the fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Nunito:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 0 0% 13.3%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 215 20% 65%;
    --input: 215 20% 65%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;

    /* Add font family variables */
    --font-heading: 'Raleway', sans-serif;
    --font-body: 'Nunito', sans-serif;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 215 20% 35%;
    --input: 215 20% 35%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground font-body;
  }
  /* Apply Raleway to all headings */
  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
}

@layer utilities {
  .scrollbar-custom::-webkit-scrollbar {
    width: 12px;
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    background: theme("colors.gray.200");
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background: theme("colors.gray.900");
    border-radius: 6px;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background: theme("colors.gray.500");
  }
}

.markdown-content {
  font-family: var(--font-body);
  font-size: .905em;
  line-height: 1.25;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  font-family: var(--font-heading);
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.markdown-content h1 { font-size: 2em; }
.markdown-content h2 { font-size: 1.5em; }
.markdown-content h3 { font-size: 1.25em; }
.markdown-content p {
  margin-bottom: 1em;
  font-family: var(--font-body);
}

.markdown-content hr {
  margin: 2em 0;
}

.markdown-content ul,
.markdown-content ol {
  margin-bottom: 1em;
  padding-left: 2em;
  font-family: var(--font-body);
}

.markdown-content li {
  margin-bottom: 0.5em;
}

.markdown-content a {
  color: #0066cc;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content blockquote {
  border-left: 4px solid #ddd;
  padding-left: 1em;
  margin-left: 0;
  font-style: italic;
  font-family: var(--font-body);
  overflow-x: auto;
  max-width: 95%;
}

.markdown-content code {
  background-color: #777676;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
  overflow-x: auto;
  max-width: 95%;
  color: #121212;
}

.markdown-content pre {
  background-color: #777676;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
  max-width: 95%;
  color: #121212;
}

.popover-content,
.popover-content * {
  transition: none !important;
  animation: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(0); /* or a tiny nudge if you like */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(0);
  }
}

/* A utility class that uses Radix data attributes */
.sheet-fade[data-state="open"] {
  animation: fadeIn 200ms ease-out;
}
.sheet-fade[data-state="closed"] {
  animation: fadeOut 200ms ease-in;
}

.magical-gradient {
  background: linear-gradient(
    45deg,
    #ff3bff 0%,
    #5c24ff 25%,
    #0088ff 50%,
    #5c24ff 75%,
    #ff3bff 100%
  );
  background-size: 400% 400%;
  animation: magical-shimmer 12s ease infinite;
}

@keyframes magical-shimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.scroll-chevron-glow {
  animation: bounce-glow 2.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.3));
}

@keyframes bounce-glow {
  0%, 100% {
    transform: translateY(0);
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.3));
  }
  50% {
    transform: translateY(12px);
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.5));
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  90% {
    transform: scale(1);
    opacity: 1;
  }
}

.play-icon {
  display: inline-block;
  color: #fff; /* Change to your desired color */
  cursor: pointer;
  animation: pulse 1.5s infinite ease-in-out;
  transition: transform 0.3s ease;
}

.text-inter {
  color: #222222;
}

.blink {
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}